import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useStore } from 'store/store';
import { STYLES_BULL } from '../../../constants';

import './Info.scss';

const bull = (
  <Box
    component="span"
    sx={STYLES_BULL}
  >
    •
  </Box>
);

interface IInfoProps {
    children?: ReactNode,
}

const Info: FC<IInfoProps> = ({ children }) => {
  const { t } = useTranslation();
  const { product, line } = useStore((state) => state.orderData);

  return (
    <Card className="info">
      <CardContent className="info-content">
        <Typography color="text.disabled">
          {t('aside_title_1')}
        </Typography>
        <Typography sx={{ marginBottom: '16px' }}>
          {product}
          {bull}
          Digital print
        </Typography>
        <Typography color="text.disabled">
          {t('aside_title_2')}
        </Typography>
        <Typography>
          {line}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default memo(Info);
