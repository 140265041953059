import { FC, forwardRef, useMemo } from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import './SnackBar.scss';

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={4} ref={ref} variant="filled" {...props} />;
});

interface ISnackBarProps {
    isOpen: boolean;
    successes: string[];
    errors: string[];
    handleClose: () => void;
}

const SnackBar: FC<ISnackBarProps> = ({
  isOpen, successes, errors, handleClose,
}) => {
  const classes = useMemo(() => (errors.length ? 'error' : 'success'), [errors]);
  const messages = useMemo(() => (errors.length ? errors : successes), [errors, successes]);

  return (
    <Snackbar
      className="shared-alert"
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert
        className={`alert_${classes}`}
        onClose={handleClose}
        severity={classes}
      >
        {
          messages.map((message) => (
            <p key={message}>{message}</p>
          ))
        }
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
