import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Chip from '@mui/material/Chip';

import './StepName.scss';

interface IStepNameProps {
  step: number;
}

const StepName: FC<IStepNameProps> = ({ step }) => {
  const { t } = useTranslation();
  return (
    <div className="shared-stepName">
      <Chip label={step} />
      <span>{t(`step_${step}`)}</span>
    </div>
  );
};

export default memo(StepName);
