import { FC, memo } from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import './FileName.scss';

interface IFileNameProps {
    textStyle: string;
    title?: string;
}

const EMPTY_TITLE = 'No file name';

const FileName: FC<IFileNameProps> = ({ textStyle, title }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Tooltip title={title || 'file'}>
        <Typography
          className={`shared-fileName ${textStyle}`}
          variant="inherit"
        >
          {title || EMPTY_TITLE}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default memo(FileName);
