import {
  FC, useMemo, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Progress from 'components/shared/progress/Progress';
import FileName from 'components/shared/fileName/FileName';
import { useStore } from 'store/store';
import { Statuses } from 'enums';
import { STYLES_BUTTON } from '../../../../../../constants';

import './DropzoneTextCancel.scss';

interface IDropzoneTextCancelProps {
  reupload: boolean;
  fileName?: string;
}

const DropzoneTextCancel: FC<IDropzoneTextCancelProps> = ({ reupload, fileName }) => {
  const { t } = useTranslation();
  const cancelJob = useStore((state) => state.cancelJob);
  const taskStatus = useStore((state) => state.taskStatus);
  const [isCancel, setIsCancel] = useState(false);

  const isShowCancel = useMemo(() => [
    Statuses.processing,
    Statuses.pending,
  ].includes(taskStatus.status), [taskStatus]);

  const handleCancel = useCallback(() => {
    setIsCancel(true);
    cancelJob();
  }, []);

  return (
    <div className="dropzoneText">
      <Progress />
      <FileName textStyle="dropzoneText__fileName" title={fileName} />
      <Typography className="dropzoneText__tip" color="text.disabled">
        {t('drag_await_tip')}
      </Typography>
      {!reupload && (
        <Button
          variant="contained"
          sx={STYLES_BUTTON}
          onClick={handleCancel}
          disabled={isCancel || !isShowCancel}
        >
          {t('btn_cancel')}
        </Button>
      )}
    </div>
  );
};

export default DropzoneTextCancel;
