import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { STYLES_BUTTON } from '../../../constants';
import TemplatePdf from '../../../assets/Template.pdf';

import './Template.scss';

const Template: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="template">
      <Typography>
        {t('template')}
      </Typography>
      <a
        href={TemplatePdf}
        download="Template"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="contained"
          sx={STYLES_BUTTON}
        >
          {t('btn_template')}
        </Button>
      </a>
    </div>
  );
};

export default memo(Template);
