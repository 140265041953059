import {
  FC, useCallback, useState, MouseEvent,
} from 'react';

import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import { ReactComponent as Rotate } from 'assets/icons/Rotate.svg';
import { RotateActions } from 'enums';

import './RotateIconButton.scss';

interface IRotateIconButtonProps {
  text: string;
  disabled: boolean;
  actions: string[];
  handler: (action: string) => Promise<void>;
}

const RotateIconButton: FC<IRotateIconButtonProps> = ({
  text, actions, disabled, handler,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorEl(null);
  }, []);
  const handleActionClick = useCallback((action: string) => {
    setOpen(false);
    setAnchorEl(null);
    handler(action);
  }, []);

  return (
    <Box sx={{ display: 'contents' }}>
      <Button
        className="shared-iconButton"
        aria-haspopup="true"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled}
        onClick={handleOpen}
      >
        <SvgIcon>
          <Rotate />
        </SvgIcon>
        <Typography>{text}</Typography>
      </Button>
      <Menu
        className="shared-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        TransitionComponent={Fade}
      >
        {Object.keys(RotateActions).map((action, index) => (
          <MenuItem
            key={action}
            disabled={disabled}
            onClick={() => handleActionClick(action)}
          >
            <Typography>
              {actions[index]}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default RotateIconButton;
