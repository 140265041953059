import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import { AppRoutes } from 'enums';
import { STYLES_BUTTON } from '../../../../constants';

import './NotFound.scss';

const GO_TO_HOME = 'Go to home page';

const NotFoundPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="notFound">
      <h2>{t('label_not_found')}</h2>
      <Button
        variant="contained"
        sx={STYLES_BUTTON}
        onClick={() => navigate(AppRoutes.base)}
      >
        {GO_TO_HOME}
      </Button>
    </div>
  );
};

export default NotFoundPage;
