import {
  Dispatch,
  FC, SetStateAction, useEffect, useMemo, useCallback,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import SvgIcon from '@mui/material/SvgIcon';
import { useStore } from 'store/store';
import { humanFileSize } from 'services/fileService/FileSize';
import { normalizeFileName } from 'services/fileService/FileName';
import { Statuses } from 'enums';
import { ReactComponent as Plus } from 'assets/icons/Plus_White.svg';

import DropzoneText from './dropzoneText/DropzoneText';
import DropzoneTextCancel from './dropzoneTextCancel/DropzoneTextCancel';
import { MAX_FILE_SIZE, FORMATS, PDF_FORMAT } from '../../../../../constants';

import './Dropzone.scss';

interface IDropzoneProps {
  reupload: boolean;
  setSuccesses: Dispatch<SetStateAction<string[]>>;
  setErrors: Dispatch<SetStateAction<string[]>>;
}

const Dropzone: FC<IDropzoneProps> = ({ reupload, setSuccesses, setErrors }) => {
  const { t } = useTranslation();
  const {
    taskStatus, setTaskStatus, fileData,
    setFileData, uploadFile, destroySession,
  } = useStore(useShallow((state) => (
    {
      taskStatus: state.taskStatus,
      orderData: state.orderData,
      fileData: state.fileData,
      setTaskStatus: state.setTaskStatus,
      setFileData: state.setFileData,
      uploadFile: state.uploadFile,
      destroySession: state.destroySession,
    }
  )));

  const translateMap = useMemo(() => {
    return {
      'file-invalid-type': reupload ? 'snackbar_wrong_pdf' : 'snackbar_wrong_type',
      'file-too-large': 'snackbar_wrong_size',
    };
  }, [reupload]);

  const onDrop = useCallback((acceptedFiles: File[]): void => {
    if (!acceptedFiles[0]) return;
    const file = acceptedFiles[0];
    const reader = new FileReader();

    setFileData({ name: file.name, size: humanFileSize(file.size, 0) });
    reader.onabort = () => console.log('File reading was aborted');
    reader.onerror = () => console.log('File reading has failed');
    reader.onload = async () => {
      setTaskStatus(Statuses.uploading);
      try {
        await destroySession();
        const data = new FormData();
        data.set('file', file, normalizeFileName(file.name));

        await uploadFile(reupload, data)
          .then(() => {
            if (reupload) {
              setSuccesses([t('snackbar_success_full')]);
              setErrors([]);
              setTaskStatus(Statuses.starting);
            } else {
              setTaskStatus(Statuses.processing);
            }
          });
      } catch (error) {
        setTaskStatus(Statuses.starting);
        setErrors([t('snackbar_failed')]);
        console.error(error);
      }
    };
    reader.readAsArrayBuffer(file);
  }, []);

  const {
    getRootProps, getInputProps, isDragActive, fileRejections,
  } = useDropzone({
    onDrop,
    multiple: false,
    preventDropOnDocument: true,
    accept: reupload ? PDF_FORMAT : FORMATS,
    maxSize: Number(MAX_FILE_SIZE) * 1024 * 1024,
  });

  useEffect(() => {
    if (fileRejections.length === 1) {
      setErrors(
        fileRejections[0].errors.map(
          ({ code }) => t(translateMap[code as keyof typeof translateMap]),
        ),
      );
    }
  }, [fileRejections]);

  const isUpload = useMemo(() => [
    Statuses.uploading,
    Statuses.processing,
    Statuses.pending,
    Statuses.completed,
  ].includes(taskStatus.status), [taskStatus]);

  const dropzone = useMemo(() => (
    (
      <div
        {...getRootProps({
          className: 'dropzone__container',
        })}
      >
        <input {...getInputProps()} />
        {
          isUpload
            ? <DropzoneTextCancel reupload={reupload} />
            : <DropzoneText reupload={reupload} />
        }
      </div>
    )
  ), [isUpload, reupload]);

  const dropzoneHover = useMemo(() => (
    (
      <div
        className={`dropzone__fade ${isDragActive ? 'visible' : ''}`}
      >
        <SvgIcon sx={{ fontSize: 100 }}>
          <Plus />
        </SvgIcon>
      </div>
    )
  ), [isDragActive]);

  return (
    <section className="dropzone">
      {!isUpload
        ? dropzone
        : (
          <div className="dropzone__container noDrop">
            <DropzoneTextCancel
              reupload={reupload}
              fileName={fileData.name}
            />
          </div>
        )}
      {!isUpload && dropzoneHover}
    </section>
  );
};

export default Dropzone;
