import { FC } from 'react';
import Progress from 'components/shared/progress/Progress';

import './Loading.scss';

const Loading: FC = () => {
  return (
    <section className="loading" title="Starting application...">
      <Progress />
    </section>
  );
};

export default Loading;
