import { FC } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate, RoutesProps,
} from 'react-router-dom';
import { AppRoutes } from 'enums';

import InitJob from 'components/layouts/pages/initJob/InitJob';
import NotFoundPage from 'components/layouts/pages/notFound/NotFound';
import UploadPage from '../layouts/pages/upload/Upload';
import FeedbackPage from '../layouts/pages/feedback/Feedback';
import ProofPage from '../layouts/pages/proof/Proof';
import LangInit from './LangInit';

const CustomRoutes = (props: RoutesProps) => {
  LangInit();
  return (<Routes {...props}>{props?.children}</Routes>);
};

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <CustomRoutes>
        <Route path={AppRoutes.base} element={<InitJob />} />
        <Route path={AppRoutes.notFound} element={<NotFoundPage />} />
        <Route path={AppRoutes.upload} element={<UploadPage reupload={false} />} />
        <Route path={AppRoutes.feedback} element={<FeedbackPage />} />
        <Route path={AppRoutes.reupload} element={<UploadPage reupload />} />
        <Route path={AppRoutes.proof} element={<ProofPage />} />
        <Route path="*" element={<Navigate to={AppRoutes.notFound} replace />} />
      </CustomRoutes>
    </BrowserRouter>
  );
};

export default AppRouter;
