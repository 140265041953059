import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Plus } from 'assets/icons/Plus.svg';

import './DropzoneText.scss';

interface IDropzoneTextProps {
  reupload: boolean;
}

const DropzoneText: FC<IDropzoneTextProps> = ({ reupload }) => {
  const { t } = useTranslation();
  return (
    <div className="dropzoneText">
      <SvgIcon sx={{ fontSize: 50 }}>
        <Plus />
      </SvgIcon>
      <Typography className="dropzoneText__title">
        {t('drag_title')}
      </Typography>
      <Typography className="dropzoneText__note">
        {t('drag_note')}
      </Typography>
      <Typography className="dropzoneText__files" color="text.disabled">
        {t(reupload ? 'drag_pdf' : 'drag_files')}
      </Typography>
    </div>
  );
};

export default DropzoneText;
