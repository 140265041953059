import {
  FC, useMemo, useState, Dispatch, SetStateAction, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import type { IModalState } from 'store/interfaces/IData';
import { useStore } from 'store/store';
import StepName from 'components/shared/stepName/StepName';
import CommentModal from 'components/shared/commentModal/CommentModal';
import { STYLES_BUTTON } from '../../../../../constants';

import './Download.scss';

interface IDownloadProps {
  isAllowed: boolean;
  setIsAllowed: Dispatch<SetStateAction<boolean>>
}

const Download: FC<IDownloadProps> = ({ isAllowed, setIsAllowed }) => {
  const { t } = useTranslation();
  const {
    fileData, preflightedFile, approveJob, rejectJob,
  } = useStore(useShallow((state) => ({
    fileData: state.fileData,
    preflightedFile: state.preflightedFile,
    approveJob: state.approveJob,
    rejectJob: state.rejectJob,
  })));

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState<IModalState>({
    open: false,
    comments: '',
  });
  const downloadName = useMemo(() => `${fileData.name.split('.')[0]}.pdf`, [fileData.name]);

  const handleApprove = useCallback(async () => {
    setIsLoading(true);
    await approveJob();
    setIsLoading(false);
    setIsAllowed(true);
  }, []);
  const handleSend = useCallback(async () => {
    setIsLoading(true);
    setModal((prev) => ({ ...prev, open: false }));
    await rejectJob({ comments: modal.comments });
    setIsLoading(false);
    setIsAllowed(true);
  }, [modal]);

  return (
    <Card className="downloadForm">
      <CardContent className="downloadForm-content">
        <div className="downloadForm-content__header">
          <StepName step={4} />
          <a
            className={`${!preflightedFile.length ? 'disabled' : ''}`}
            href={preflightedFile}
            download={downloadName}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              className="downloadForm-content__header__btn"
              variant="contained"
              disabled={!preflightedFile.length}
            >
              <FileDownloadOutlinedIcon fontSize="small" />
              {t('btn_download')}
            </Button>
          </a>
        </div>
        <div className="downloadForm-content__list">
          <Button
            className="downloadForm-content__list__btn"
            variant="contained"
            sx={STYLES_BUTTON}
            disabled={isAllowed || isLoading}
            onClick={handleApprove}
          >
            {t('btn_agree')}
          </Button>
          <Button
            className="downloadForm-content__list__btn"
            variant="contained"
            sx={STYLES_BUTTON}
            disabled={isAllowed || isLoading}
            onClick={() => setModal({ ...modal, open: true })}
          >
            {t('btn_disagree')}
          </Button>
          <CommentModal
            state={modal}
            setState={setModal}
            handleClick={handleSend}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default Download;
