import { create } from 'zustand';
import { QueryParams, Statuses, FolderNames } from 'enums';
import {
  post, postFormData, remove, getData,
} from 'services/api/api';
import type { IState, IStore } from './interfaces/IStore';
import type {
  IOrderData, IFileData, IStatus, IJobInfo, IPreviewImage, ICheck,
  IActionRequest, IAcceptRequest, IRejectRequest, IResult, IResultProof,
} from './interfaces/IData';
import {
  API_URL, DEFAULT_IV_HEIGHT, DEFAULT_IV_WIDTH, DEFAULT_LANG,
} from '../constants';

const initialState: IState = {
  isLoaded: false,
  isProof: true,
  jobId: '',
  preflightedFile: '',
  checks: [],
  taskStatus: {
    status: Statuses.starting,
    error: '',
  },
  orderData: {
    product: '',
    line: '',
    ivWidth: DEFAULT_IV_WIDTH,
    ivHeight: DEFAULT_IV_HEIGHT,
    lang: DEFAULT_LANG,
  },
  fileData: {
    name: '',
    size: '',
  },
  imageData: {
    url: '',
    height: 0,
    width: 0,
  },
};

export const useStore = create<IStore>((set, get) => ({
  ...initialState,
  reset: () => set(initialState),
  setLoaded: (loaded: boolean) => set({ isLoaded: loaded }),
  setIsProof: (proof: boolean) => set({ isProof: proof }),
  setPreflightedFile: (url: string) => set({ preflightedFile: url }),
  setJobId: (id: string) => set({ jobId: id }),
  setTaskStatus: (newStatus: Statuses) => set({
    taskStatus: {
      status: newStatus,
      error: '',
    },
  }),
  setOrderData: (order: IOrderData) => set({ orderData: order }),
  setFileData: (file: IFileData) => set({ fileData: file }),
  setChecks: (newChecks: ICheck[]) => set({ checks: newChecks }),
  setImageData: (newImageData: IPreviewImage) => set({ imageData: newImageData }),

  destroySession: async () => {
    const url = `${API_URL}/sessions/destroySession?${QueryParams.JOB_ID}=${get().jobId}`;
    await remove(url);
  },
  getJobInfo: async (jobId: string) => {
    const url = `${API_URL}/resources/info?${QueryParams.JOB_ID}=${jobId}`;
    const result: IJobInfo = await fetch(url, {
      credentials: 'include',
    }).then((res) => res.clone().json());
    get().setIsProof(result.generateProofPage);
    return result;
  },
  uploadFile: async (isReupload: boolean, settings: FormData) => {
    const url = `${API_URL}/resources/${isReupload ? 'reupload' : 'upload'}?${QueryParams.JOB_ID}=${get().jobId}`;
    if (isReupload) {
      settings.set('generateProofPage', String(get().isProof));
    } else {
      settings.set('ivWidth', String(get().orderData.ivWidth));
      settings.set('ivHeight', String(get().orderData.ivHeight));
      settings.set('language', get().orderData.lang);
    }
    await postFormData(url, settings);
  },
  cancelJob: async () => {
    const url = `${API_URL}/resources/cancel?${QueryParams.JOB_ID}=${get().jobId}`;
    await getData(url);
  },
  getTaskStatus: async () => {
    const url = `${API_URL}/taskStatus?${QueryParams.JOB_ID}=${get().jobId}`;
    await fetch(url, {
      credentials: 'include',
    })
      .then((res) => res.clone().json())
      .then((taskStatus: IStatus) => get().setTaskStatus(taskStatus.status));
  },
  getResult: async () => {
    const url = `${API_URL}/resources/getResult?${QueryParams.JOB_ID}=${get().jobId}`;
    const result: IResult = await fetch(url, {
      credentials: 'include',
    }).then(async (res) => {
      const response: IResult = await res.clone().json();
      get().setChecks(response.reportResult.checks);
      return response;
    });
    return result;
  },
  getReportFileByName: async (folder: FolderNames, filename: string) => {
    const url = `${API_URL}/${folder}/${filename}`;
    const file = await fetch(url, {
      credentials: 'include',
    }).then((response) => response.blob());
    return file;
  },
  changeFile: async (rawBody: IActionRequest) => {
    const url = `${API_URL}/resources/action?${QueryParams.JOB_ID}=${get().jobId}`;
    const body = { ...rawBody, language: get().orderData.lang };
    await post(url, body);
  },
  resetFile: async () => {
    const url = `${API_URL}/resources/resetAction?${QueryParams.JOB_ID}=${get().jobId}`;
    await post(url);
  },
  acceptJob: async (body: IAcceptRequest) => {
    const url = `${API_URL}/resources/accept?${QueryParams.JOB_ID}=${get().jobId}`;
    await post(url, body);
  },
  getResultProof: async () => {
    const url = `${API_URL}/resources/getProofPage?${QueryParams.JOB_ID}=${get().jobId}`;
    const result: IResultProof = await fetch(url, {
      credentials: 'include',
    }).then(async (res) => {
      const response: IResultProof = await res.clone().json();
      return response;
    });
    return result;
  },
  approveJob: async () => {
    const url = `${API_URL}/resources/approve?${QueryParams.JOB_ID}=${get().jobId}`;
    await getData(url);
  },
  rejectJob: async (body: IRejectRequest) => {
    const url = `${API_URL}/resources/reject?${QueryParams.JOB_ID}=${get().jobId}`;
    await post(url, body);
  },
}));
