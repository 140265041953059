import { FC } from 'react';

import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress';

const SIZE = 100;
const THICKNESS = 2;

const Progress: FC<CircularProgressProps> = (props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        {...props}
        sx={{ color: 'white' }}
        variant="determinate"
        size={SIZE}
        thickness={THICKNESS}
        value={100}
      />
      <CircularProgress
        {...props}
        sx={{
          color: 'rgb(227, 6, 13)',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        variant="indeterminate"
        size={SIZE}
        thickness={THICKNESS}
        disableShrink
      />
    </Box>
  );
};

export default Progress;
