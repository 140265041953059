import {
  FC, useState, useMemo, useEffect,
  ChangeEvent, MouseEvent, KeyboardEvent, FocusEvent,
} from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Select } from '@mui/base/Select';
import { Option } from '@mui/base/Option';

import { QueryParams, OrderStatuses, AppLangs } from 'enums';
import { useStore } from 'store/store';
import {
  STYLES_BUTTON, DEFAULT_IV_WIDTH, DEFAULT_IV_HEIGHT, STORAGE_LANG, DEFAULT_LANG,
} from '../../../../constants';

import './InitJob.scss';

const MOCK_PRODUCT = 'Rechte vlag';
const MOCK_LINE = 'Vlag voor Stichting de Groene Kokmeeuw';

const TITLE = 'Artwork info';
const TITLE_UPLOAD = 'Upload page';
const TITLE_REUPLOAD = 'Reupload page';
const TITLE_PROOF = 'Proof page';

const REUPLOAD__REJECTED_STATUSES = [OrderStatuses.created, OrderStatuses.uploaded, null];
const PROOF__REJECTED_STATUSES = [
  OrderStatuses.created,
  OrderStatuses.uploaded,
  OrderStatuses.approved,
  OrderStatuses.rejected,
  null,
];

const initInfoState = {
  id: '',
  status: null,
  generateProofPage: false,
  comments: '',
};

const InitJob: FC = () => {
  const { getJobInfo } = useStore();
  const [jobId, setJobId] = useState(uuidv4());
  const [lang, setLang] = useState<string | null>(() => {
    return localStorage.getItem(STORAGE_LANG) || DEFAULT_LANG;
  });
  const [sizes, setSizes] = useState({
    ivWidth: `${DEFAULT_IV_WIDTH}`,
    ivHeight: `${DEFAULT_IV_HEIGHT}`,
  });
  const [info, setInfo] = useState(initInfoState);
  const [generateProofPage, setGenerateProofPage] = useState(true);

  const handleJobId = (event: ChangeEvent<HTMLInputElement>) => {
    setJobId(event.target.value);
  };
  const handleLang = (_event: MouseEvent<Element, globalThis.MouseEvent>
                          | KeyboardEvent<Element>
                          | FocusEvent<Element, Element>
                          | null, newLang: string | null) => {
    if (newLang == null) return;
    setLang(newLang);
    localStorage.setItem(STORAGE_LANG, newLang);
  };
  const handleWidth = (event: ChangeEvent<HTMLInputElement>) => {
    setSizes({
      ...sizes,
      ivWidth: event.target.value,
    });
  };
  const handleHeight = (event: ChangeEvent<HTMLInputElement>) => {
    setSizes({
      ...sizes,
      ivHeight: event.target.value,
    });
  };

  const uploadLink = useMemo(() => {
    const iv_width = Number.isInteger(+sizes.ivWidth) ? sizes.ivWidth : DEFAULT_IV_WIDTH;
    const iv_height = Number.isInteger(+sizes.ivHeight) ? sizes.ivHeight : DEFAULT_IV_HEIGHT;
    return `/upload?${QueryParams.JOB_ID}=${jobId}&${QueryParams.LANG}=${lang}&${QueryParams.ORDER_PRODUCT}=${MOCK_PRODUCT}&${QueryParams.ORDER_LINE}=${MOCK_LINE}&${QueryParams.IV_WIDTH}=${iv_width}&${QueryParams.IV_HEIGHT}=${iv_height}&${QueryParams.GENERATE_PROOF_PAGE}=${generateProofPage}`;
  }, [jobId, lang, sizes, generateProofPage]);

  const reuploadLink = useMemo(() => {
    const iv_width = Number.isInteger(+sizes.ivWidth) ? sizes.ivWidth : DEFAULT_IV_WIDTH;
    const iv_height = Number.isInteger(+sizes.ivHeight) ? sizes.ivHeight : DEFAULT_IV_HEIGHT;
    return `/reupload?${QueryParams.JOB_ID}=${jobId}&${QueryParams.LANG}=${lang}&${QueryParams.ORDER_PRODUCT}=${MOCK_PRODUCT}&${QueryParams.ORDER_LINE}=${MOCK_LINE}&${QueryParams.IV_WIDTH}=${iv_width}&${QueryParams.IV_HEIGHT}=${iv_height}`;
  }, [jobId, lang, sizes]);

  const proofLink = useMemo(() => {
    const iv_width = Number.isInteger(+sizes.ivWidth) ? sizes.ivWidth : DEFAULT_IV_WIDTH;
    const iv_height = Number.isInteger(+sizes.ivHeight) ? sizes.ivHeight : DEFAULT_IV_HEIGHT;
    return `/proof?${QueryParams.JOB_ID}=${jobId}&${QueryParams.LANG}=${lang}&${QueryParams.ORDER_PRODUCT}=${MOCK_PRODUCT}&${QueryParams.ORDER_LINE}=${MOCK_LINE}&${QueryParams.IV_WIDTH}=${iv_width}&${QueryParams.IV_HEIGHT}=${iv_height}`;
  }, [jobId, lang, sizes]);

  const reuploadEnabled = useMemo(() => !REUPLOAD__REJECTED_STATUSES.includes(info.status), [info]);
  // eslint-disable-next-line max-len
  const proofEnabled = useMemo(() => !PROOF__REJECTED_STATUSES.includes(info.status) && info.generateProofPage, [info]);

  useEffect(() => {
    if (jobId.length) {
      getJobInfo(jobId)
        .then((result: any) => setInfo(result))
        .catch(() => setInfo(initInfoState));
    }
  }, [jobId]);

  return (
    <section className="main-container">
      <div className="outer-box">
        <div className="inner-box">
          <h1 className="title">{TITLE}</h1>
          <TextField
            className="textfield"
            label={QueryParams.JOB_ID}
            variant="outlined"
            value={jobId}
            onChange={handleJobId}
          />
          <Paper className="paper status">
            {`Status: ${info.status || 'unknown'}`}
          </Paper>
          <Paper className="paper">
            {`Comments: ${info.comments || 'unknown'}`}
          </Paper>
          <TextField
            className="textfield"
            label={QueryParams.IV_WIDTH}
            variant="outlined"
            value={sizes.ivWidth}
            onChange={handleWidth}
          />
          <TextField
            className="textfield"
            label={QueryParams.IV_HEIGHT}
            variant="outlined"
            value={sizes.ivHeight}
            onChange={handleHeight}
          />
          <Select className="select" defaultValue={localStorage.getItem(STORAGE_LANG) || DEFAULT_LANG} onChange={handleLang}>
            {Object.values(AppLangs).map((lang) => (
              <Option key={lang} className="option" value={lang}>
                Language:&nbsp;
                {lang}
              </Option>
            ))}
          </Select>
          <FormControlLabel
            label="Generate Proof Page"
            className="checkbox"
            control={(
              <Checkbox
                checked={generateProofPage}
                onChange={(e) => setGenerateProofPage(e.target.checked)}
              />
          )}
          />
          <Button variant="contained" sx={STYLES_BUTTON} style={{ background: 'rgb(227, 6, 13)' }}>
            <Link
              style={{ textDecoration: 'none', color: 'white' }}
              to={uploadLink}
            >
              {TITLE_UPLOAD}
            </Link>
          </Button>
          <Button variant="contained" sx={STYLES_BUTTON} style={{ background: reuploadEnabled ? 'rgb(227, 6, 13)' : 'black' }}>
            <Link
              style={{ textDecoration: 'none', color: 'white' }}
              to={reuploadLink}
            >
              {TITLE_REUPLOAD}
            </Link>
          </Button>
          <Button variant="contained" sx={STYLES_BUTTON} style={{ background: proofEnabled ? 'rgb(227, 6, 13)' : 'black' }}>
            <Link
              style={{ textDecoration: 'none', color: 'white' }}
              to={proofLink}
            >
              {TITLE_PROOF}
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default InitJob;
