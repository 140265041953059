import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useStore } from 'store/store';
import { STYLES_BUTTON } from '../../../constants';

import './DeleteForm.scss';

interface IhandleClickProps {
  handleClick: () => void;
  disabled?: boolean;
}

const DeleteForm: FC<IhandleClickProps> = ({ handleClick, disabled = false }) => {
  const { t } = useTranslation();
  const { name, size } = useStore((state) => state.fileData);
  const ext = useMemo(() => `.${name.split('.').at(-1)}`, [name]);

  return (
    <div className="shared-deleteForm">
      <div className="fileInfo">
        <p className="fileInfo__fileExt">
          {ext}
        </p>
        <div className="fileInfo__fileData">
          <Typography>{name}</Typography>
          <Typography color="text.disabled">{size}</Typography>
        </div>
      </div>
      <Button
        variant="contained"
        sx={STYLES_BUTTON}
        disabled={disabled}
        onClick={handleClick}
      >
        {t('btn_delete')}
      </Button>
    </div>
  );
};

export default memo(DeleteForm);
