import {
  FC, Dispatch, SetStateAction, ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

import type { IModalState } from 'store/interfaces/IData';
import { STYLES_BUTTON } from '../../../constants';

import './CommentModal.scss';

interface ICommentModalProps {
  state: IModalState;
  setState: Dispatch<SetStateAction<IModalState>>;
  handleClick: () => Promise<void>;
}

const TEXTAREA_ROWS = 10;

const CommentModal: FC<ICommentModalProps> = ({ state, setState, handleClick }) => {
  const { t } = useTranslation();
  const { open, comments } = state;

  const handleComments = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setState((prev) => ({ ...prev, comments: event.target.value }));
  };

  return (
    <Modal
      open={open}
      onClose={() => setState({ open: false, comments: '' })}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={open}>
        <div className="shared-modal">
          <Typography>{t('label_comments')}</Typography>
          <textarea
            rows={TEXTAREA_ROWS}
            onChange={handleComments}
          />
          <div className="shared-modal__btnWrapper">
            <Button
              variant="contained"
              sx={STYLES_BUTTON}
              disabled={!comments.trim().length}
              onClick={handleClick}
            >
              {t('btn_comment')}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CommentModal;
