import {
  FC, useEffect, useState, useCallback,
} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { IJobInfo, IReportResult, ServerResponse } from 'store/interfaces/IData';
import {
  AppRoutes, QueryParams, Statuses, OrderStatuses, FolderNames,
} from 'enums';
import { useStore } from 'store/store';

import SnackBar from 'components/shared/snackBar/SnackBar';
import Info from 'components/shared/info/Info';
import Template from 'components/shared/template/Template';
import Dropzone from './dropzone/Dropzone';
import {
  HIDE_SNACKBAR_TIME, DEFAULT_IV_HEIGHT, DEFAULT_IV_WIDTH, DEFAULT_LANG, REREQUEST_TIME,
} from '../../../../constants';

import './Upload.scss';

interface IUploadProps {
  reupload: boolean;
}

const REDIRECT_STATUSES = [OrderStatuses.created, OrderStatuses.uploaded];

const Upload: FC<IUploadProps> = ({ reupload }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    jobId, taskStatus, isProof,
    getResult, getTaskStatus, getReportFileByName, getJobInfo, setLoaded,
    setJobId, setOrderData, setChecks, setImageData, setPreflightedFile, setTaskStatus, setIsProof,
  } = useStore();

  const [result, setResult] = useState<ServerResponse>({
    preflightedFile: '',
    previewImage: '',
    report: '',
    reportResult: {} as IReportResult,
    status: Statuses.starting,
    error: '',
  });
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [successes, setSuccesses] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const closeSnackBar = useCallback(() => {
    setIsSnackBarOpen(false);
  }, []);

  useEffect(() => {
    setJobId(searchParams.get(QueryParams.JOB_ID) ?? '');
    setOrderData({
      product: searchParams.get(QueryParams.ORDER_PRODUCT) ?? '',
      line: searchParams.get(QueryParams.ORDER_LINE) ?? '',
      ivWidth: Number(searchParams.get(QueryParams.IV_WIDTH)) ?? DEFAULT_IV_WIDTH,
      ivHeight: Number(searchParams.get(QueryParams.IV_HEIGHT)) ?? DEFAULT_IV_HEIGHT,
      lang: searchParams.get(QueryParams.LANG) ?? DEFAULT_LANG,
    });
    setIsProof(searchParams.get(QueryParams.GENERATE_PROOF_PAGE)?.toLowerCase() !== 'false');
  }, []);

  useEffect(() => {
    if (reupload && jobId.length) {
      getJobInfo(jobId)
        .then((info: IJobInfo) => {
          if (REDIRECT_STATUSES.includes(info.status)) {
            navigate(AppRoutes.notFound);
          }
        })
        .catch(() => navigate(AppRoutes.notFound));
    }
  }, [reupload, jobId]);

  useEffect(() => {
    const timer = setTimeout(() => closeSnackBar(), HIDE_SNACKBAR_TIME);
    if (successes.length || errors.length) {
      setIsSnackBarOpen(true);
    }
    return () => { clearTimeout(timer); };
  }, [successes, errors]);

  useEffect(() => {
    if (reupload) return () => {};
    let intervalId: any;
    if (taskStatus.status === Statuses.processing || taskStatus.status === Statuses.pending) {
      intervalId = setInterval(() => {
        getTaskStatus().catch((error) => {
          setTaskStatus(Statuses.starting);
          console.error(error);
        });
      }, REREQUEST_TIME);
    } else if (taskStatus.status === Statuses.completed || taskStatus.status === Statuses.failed) {
      if (taskStatus.status === Statuses.failed) setIsSnackBarOpen(true);
      clearInterval(intervalId);
      getResult()
        .then((result: ServerResponse) => {
          setChecks(result.reportResult?.checks ?? []);
          setResult({
            ...result,
            status: result.status,
            error: result.error,
          });
        })
        .catch((error) => {
          setTaskStatus(Statuses.starting);
          setErrors([t('snackbar_failed')]);
          console.error(error);
        });
    }
    return () => clearInterval(intervalId);
  }, [taskStatus]);

  useEffect(() => {
    const fetchData = async () => {
      if (taskStatus.status === Statuses.completed) {
        await getReportFileByName(FolderNames.reports, result.previewImage ?? '')
          .then((blob) => {
            const img = new Image();
            const imageUrl = URL.createObjectURL(blob as Blob);
            img.src = imageUrl;
            img.onload = () => {
              setImageData({
                url: imageUrl,
                height: img.naturalHeight,
                width: img.naturalWidth,
              });
            };
          });
        if (result.preflightedFile !== undefined) {
          await getReportFileByName(FolderNames.reports, result.preflightedFile)
            .then((blob) => {
              const fileUrl = URL.createObjectURL(blob as Blob);
              setPreflightedFile(fileUrl);
            });
        }
        setTaskStatus(Statuses.starting);
        setLoaded(true);
        navigate(`${AppRoutes.feedback}?${QueryParams.GENERATE_PROOF_PAGE}=${isProof}`);
      } else if (result.status === Statuses.failed) {
        setTaskStatus(Statuses.starting);
        setErrors([
          result.error?.length ? result.error : t('snackbar_failed'),
        ]);
      }
    };
    fetchData().catch(console.error);
  }, [result]);

  return (
    <section className="main-container">
      <Dropzone
        reupload={reupload}
        setSuccesses={setSuccesses}
        setErrors={setErrors}
      />
      <div className="aside upload">
        <Info />
        {!reupload && <Template />}
      </div>
      <SnackBar
        isOpen={isSnackBarOpen}
        successes={successes}
        errors={errors}
        handleClose={closeSnackBar}
      />
    </section>
  );
};

export default Upload;
