import { FC } from 'react';
import AppRouter from './AppRouter';
import Header from '../layouts/header/Header';

import './App.scss';

const App: FC = () => {
  return (
    <div className="app-container">
      <Header />
      <main className="app-content">
        <AppRouter />
      </main>
    </div>
  );
};

export default App;
