import {
  Dispatch, FC, SetStateAction, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import EastRoundedIcon from '@mui/icons-material/EastRounded';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import type { ICheck } from 'store/interfaces/IData';
import StepName from 'components/shared/stepName/StepName';
import { STYLES_BUTTON } from '../../../../../constants';

import './ErrorsList.scss';

interface IErrorsListProps {
  errors: ICheck[];
  approve: boolean;
  setApprove: Dispatch<SetStateAction<boolean>>;
}

const ErrorsList: FC<IErrorsListProps> = ({ errors, approve, setApprove }) => {
  const { t } = useTranslation();
  useEffect(() => {
    setApprove(!errors.length);
  }, [errors]);

  return (
    <Card className={`errorsList ${approve ? 'errorsList-success' : ''}`}>
      <CardContent className="errorsList-content">
        <StepName step={1} />
        <div className="errorsList-content__list">
          <div className="errorsList-content__list__wrapper">
            {
              errors.length
                ? errors.map((error) => (
                  <Typography key={error.id}>
                    <EastRoundedIcon fontSize="small" />
                    {error.name}
                  </Typography>
                ))
                : (
                  <Typography>
                    {t('label_no_errors')}
                  </Typography>
                )
            }
          </div>
          <Button
            className="errorsList-content__list__btn"
            variant="contained"
            sx={STYLES_BUTTON}
            disabled={approve}
            onClick={() => setApprove(true)}
          >
            {t('btn_understood')}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default ErrorsList;
