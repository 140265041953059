/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, dp = 1): string => {
  const THRESH = 1024;
  const UNITS = ['kb', 'mb'];

  if (Math.abs(bytes) < THRESH) {
    return `${bytes}b`;
  }

  let u = -1;
  const r = 10 ** dp;

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= THRESH;
    // eslint-disable-next-line no-plusplus
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= THRESH && u < UNITS.length - 1);

  return `${bytes.toFixed(dp)}${UNITS[u]}`;
};
