import { FC } from 'react';

import './ScreenMessage.scss';

interface IScreenMessageProps {
    message: string;
}

const ScreenMessage: FC<IScreenMessageProps> = ({ message }) => {
  return (
    <div className="shared-screenMessage">
      <h2>{message}</h2>
    </div>
  );
};

export default ScreenMessage;
