import { FC, memo } from 'react';

import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { ReactComponent as Mirror } from 'assets/icons/Mirror.svg';
import { ReactComponent as Stretch } from 'assets/icons/Stretch.svg';
import { ReactComponent as Reset } from 'assets/icons/Reset.svg';

import type { BaseActions } from '../../../types/index';

import './IconButton.scss';

interface IIconButtonProps {
    action: BaseActions;
    text: string;
    disabled: boolean;
    onClick: () => void;
}

const ICONS = {
  mirror: <Mirror />,
  stretch: <Stretch />,
  reset: <Reset />,
};

const IconButton: FC<IIconButtonProps> = ({
  action, text, disabled, onClick,
}) => {
  return (
    <Button
      className="shared-iconButton"
      onClick={onClick}
      disabled={disabled}
    >
      <SvgIcon>
        {ICONS[action]}
      </SvgIcon>
      <Typography>
        {text}
      </Typography>
    </Button>
  );
};

export default memo(IconButton);
