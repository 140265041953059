class ApiError extends Error {
  httpCode: number;

  constructor(httpCode: number, message: string) {
    super(message);
    this.httpCode = httpCode;
  }
}

export const handleResponse = async (response: any) => {
  const contentType = response.headers.get('Content-Type').split(';')[0];
  let body;
  switch (contentType) {
    case 'application/json':
    case 'application/vnd.spring-boot.actuator.v3+json':
    case 'application/octet-stream':
      body = await response.json();
      break;
    case 'text/plain':
    case 'text/html':
      body = await response.text();
      break;
    default:
      body = await response.body;
  }
  if (response.ok) {
    return body;
  }
  const message = contentType === 'application/json' ? body.message : body;
  throw new ApiError(response.status, message);
};

export const getData = (url: string) => fetch(url, { credentials: 'include' }).then(handleResponse);

export const postFormData = (url: string, data = {}) => {
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: data as any,
  }).then(handleResponse);
};

export const post = (url: string, data = {}) => {
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(handleResponse);
};

export const put = (url: string, data = {}) => {
  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(handleResponse);
};

export const remove = (url: string) => {
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(handleResponse);
};
