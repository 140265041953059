import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import App from './components/shared/App';
import Loading from './components/layouts/pages/loading/Loading';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense fallback={<Loading />}>
    <App />
  </Suspense>,
);
