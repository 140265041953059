import { AppLangs } from 'enums';

export const STYLES_BUTTON = {
  borderRadius: '24px',
  boxShadow: 'none',
};

export const STYLES_BULL = {
  display: 'inline-block',
  mx: '4px',
};

const BASE_URL = process.env.REACT_APP_BASE_URL || '';

export const DEFAULT_LANG = process.env.REACT_APP_LANGUAGE || AppLangs.nl;

export const MAX_FILE_SIZE = process.env.REACT_APP_FILE_SIZE || '20';

export const API_URL = `${BASE_URL}api/intervlag-portal-service`;

export const REREQUEST_TIME = 500;

export const DEFAULT_IV_WIDTH = 210;

export const DEFAULT_IV_HEIGHT = 297;

export const HIDE_SNACKBAR_TIME = 4000;

export const DEFAULT_FILENAME = 'report';

export const STORAGE_LANG = 'lang';

export const FORMATS = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': [],
  'image/tiff': [],
  'image/vnd.adobe.photoshop': [],
  'application/pdf': [],
  'application/x-photoshop': [],
  'application/photoshop': [],
  'application/psd': [],
  'application/postscript': [],
};

export const PDF_FORMAT = {
  'application/pdf': [],
};
