import { PARENT_SITE_ORIGIN } from '../constants/constants';

const sendMessage = (message: object) => {
  PARENT_SITE_ORIGIN.forEach((origin: string) => {
    if (!origin) {
      return;
    }
    try {
      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(message, origin);
    } catch (e) {
      console.error('Failed to send message', e);
    }
  });
};

export const sendCloseMessage = () => {
  sendMessage({ close: true });
};
