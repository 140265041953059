import { useSearchParams } from 'react-router-dom';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

import { QueryParams, AppLangs } from 'enums';
import { STORAGE_LANG, DEFAULT_LANG } from '../../constants';

const LangInit = () => {
  const [searchParams] = useSearchParams();

  const lang_query_raw = searchParams.get(QueryParams.LANG);
  const lang_query = lang_query_raw && lang_query_raw in AppLangs ? lang_query_raw : null;
  const lang_storage = localStorage.getItem(STORAGE_LANG);
  const lang = [lang_query, lang_storage].find((l) => l) ?? DEFAULT_LANG;

  localStorage.setItem(STORAGE_LANG, lang);

  if (lang in AppLangs && i18n.language !== lang) {
    if (i18n.isInitialized) {
      i18n.changeLanguage(lang);
      return;
    }

    i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        lng: lang,
        fallbackLng: DEFAULT_LANG,
        interpolation: {
          escapeValue: false,
        },
      });
  }
};

export default LangInit;
