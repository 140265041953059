import { Dispatch, FC, SetStateAction } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import StepName from 'components/shared/stepName/StepName';
import ToggleButton from './toggleButton/ToggleButton';

import './ProofToggle.scss';

interface IProofToggleProps {
  proof: boolean;
  setProof: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}

const ProofToggle: FC<IProofToggleProps> = ({ proof, setProof, disabled }) => {
  return (
    <Card className="toggle">
      <CardContent className="toggle-content">
        <StepName step={3} />
        <div className="toggle-content-area">
          <ToggleButton value proof={proof} setProof={setProof} disabled={disabled} />
          <ToggleButton value={false} proof={proof} setProof={setProof} disabled={disabled} />
        </div>
      </CardContent>
    </Card>
  );
};

export default ProofToggle;
